/* 
div .materialui-daterange-picker-MuiPaper-elevation5-15 {
    box-shadow:none;
}


div .materialui-daterange-picker-MuiPaper-elevation5-302 {
    box-shadow:none;
}

div .materialui-daterange-picker-MuiPaper-elevation5-589 {
    box-shadow:none;
} */

.containerPicker div {
    box-shadow:none;
}

/* 
 .materialui-daterange-picker-MuiMenu-paper-244 {
     position: fixed !important; 
    top: 17% ;
    left: 10% ;

}  */

/* .materialui-daterange-picker-MuiPaper-root-7 
materialui-daterange-picker-MuiMenu-paper-244 
materialui-daterange-picker-MuiPopover-paper-247 
materialui-daterange-picker-MuiPaper-elevation8-18 
materialui-daterange-picker-MuiPaper-rounded-8 */

/* materialui-daterange-picker-MuiPaper-root-7 
materialui-daterange-picker-MuiMenu-paper-244 
materialui-daterange-picker-MuiPopover-paper-247 
materialui-daterange-picker-MuiPaper-elevation8-18  */
/* materialui-daterange-picker-MuiPaper-rounded-8 */

/* materialui-daterange-picker-MuiList-root-257 
materialui-daterange-picker-MuiMenu-list-245 
materialui-daterange-picker-MuiList-padding-258 */